import ReactPlayer from 'react-player';
import * as S from './styles';

const Caracteristici = () => {
  return (
    <S.Caracteristici>
      <h2>Caracteristici Tehnice</h2>
      <div>
        <div className='table'>
          <h3 style={{ marginTop: '0' }}>Indicatori tehnici</h3>
          <div>
            <div>
              <p>Densitatea suprafeței</p>
              <p>1,73</p>
            </div>
            <div>
              <p>Rezistența la tracțiune</p>
              <p>6,3 MPa</p>
            </div>
            <div>
              <p>Rezistența la rupere</p>
              <p>26,6 kN/m</p>
            </div>
          </div>
          <h3>Proprietăți fizice</h3>
          <div>
            <div>
              <p>Coeficientul de absorbție a apei</p>
              <p>≤ 2,5 % din masă</p>
            </div>
            <div>
              <p>Impermeabilitatea apei</p>
              <p>30 min, fără scurgeri</p>
            </div>
            <div>
              <p>Rezistența la îngheț</p>
              <p>mai mult de 100 cicluri</p>
            </div>
            <div>
              <p>Rezistența la condiții atmosferice</p>
              <p>20 ani de exploatare standard</p>
            </div>
            <div>
              <p>Rezistența la foc</p>
              <p>Clasa F-1 (nu susține arderea)</p>
            </div>
            <div>
              <p>Rezistența la abraziune</p>
              <p>≤ 0,10 (Clasa 25)</p>
            </div>
            <div>
              <p>Coeficientul de frecare statică</p>
              <p>0,07</p>
            </div>
            <div>
              <p>Rezistența la aderența țigaretelor</p>
              <p>Clasa 2</p>
            </div>
            <div>
              <p>Elasticitatea</p>
              <p>Absența fisurilor sau rupturilor</p>
            </div>
          </div>
          <h3>Proprietăți chimice</h3>
          <div>
            <div>
              <p>Rezistența la încărcare</p>
              <p>Clasa 3</p>
            </div>
            <div>
              <p>Rezistența la coroziune chimică</p>
              <p>800 ore, fără defecte vizibile</p>
            </div>
          </div>
        </div>

        <div className='video'>
          <ReactPlayer url='/IMG_0307.MP4' controls />
        </div>
      </div>
      <h3>Certificatele Deținute de Phomi</h3>
      <div className='certificate'>
        <img src='/package.jpeg' alt='Certificates' />
        <div>
          <div>
            <p>ISO9001</p>
            <p>U.S. UL Green Guard Certification</p>
            <p>Marine Equipment Directive MED Certification</p>
            <p>Malaysia Fireproof Material Certification</p>
            <p>Korea Green Label</p>
            <p>Polish Quality Certification</p>
          </div>
          <div>
            <p>ISO14021</p>
            <p>
              Certificate of Conformity of Russian Building Sanitary Use
              Regulations
            </p>
            <p>
              Certificate of Conformity With Russian Fire Protection Regulations
            </p>
            <p>European Union Fireproof Material Certification</p>
            <p>Singapore Green Label</p>
          </div>
        </div>
      </div>
    </S.Caracteristici>
  );
};

export default Caracteristici;
