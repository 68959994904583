import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import Star from './Star';
import { Link, useHistory } from 'react-router-dom';
import { products } from '../../products';

const Produse = () => {
  const history = useHistory();
  return (
    <S.Produse id='galerie'>
      <h2>Produsele Noastre</h2>
      <p>
        Descoperă gama noastră diversificată de categorii, fiecare creată pentru
        a îmbunătăți și personaliza spațiul tău. <br />
        Alege dintr-o varietate de texturi, culori și finisaje pentru a
        transforma orice proiect într-o capodoperă.
      </p>
      <Swiper
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        className='mySwiper'
        spaceBetween={12}
        slidesPerView={4}
        slidesPerGroup={2}
        autoplay={{
          delay: 2000,
        }}
        breakpoints={{
          346: {
            // width: 576,
            slidesPerView: 2,
          },
          768: {
            // width: 768,
            slidesPerView: 4,
          },
        }}>
        {products.slice(0, 12).map((elem, i) => (
          <SwiperSlide>
            <div
              onClick={() => {
                history.push(`/produse?category=${elem?.category_name}`);
                window.scrollTo(0, 0);
              }}>
              <p className='vezi'>Vezi Produsele</p>
              <img src={`${elem?.path}/category.jpg`} alt='produs' />
              <h3>{elem?.category_name}</h3>
              <p>
                De la {elem?.price_small} MDL/m<span>2</span>
              </p>
              <h5>{elem?.variety.length} produse</h5>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='other_blocks'>
        {products.slice(12, 16).map((elem, i) => (
          <div
            onClick={() =>
              history.push(`/produse?category=${elem?.category_name}`)
            }>
            <p className='vezi'>Vezi Produsele</p>
            <img src={`${elem?.path}/category.jpg`} alt='produs' />
            <h3>{elem?.category_name}</h3>
            <p>
              De la {elem?.price_small} MDL/m<span>2</span>
            </p>
            <h5>{elem?.variety.length} produse</h5>
          </div>
        ))}
      </div>
      <Link to='/produse'>Vezi Toate Produsele</Link>
    </S.Produse>
  );
};

export default Produse;
