import { Link, useHistory } from 'react-router-dom';
import * as S from './styles';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  const history = useHistory();
  return (
    <S.Footer>
      <div>
        <div>
          <img
            src='/Phomi_Logo_Black_Green_Trasnparent_Background.png'
            alt='logo'
            onClick={() => {
              history.push('/');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
          <p style={{ marginBottom: '12px' }}>
            Phomi redefinește materialele de construcție cu soluții inovatoare
            și ecologice de ceramica flexibilă, adaptate pentru o gamă largă de
            proiecte arhitecturale.
          </p>
          <p
            className='line'
            onClick={() =>
              window.open('https://maps.app.goo.gl/vSY3wPcN6pJGd3Bc7', '_blank')
            }>
            Chișinău, str.Puskin 56
          </p>
          <p
            className='line'
            onClick={() => (window.location.href = 'tel:079900999')}>
            079 900 999
          </p>
        </div>
        <div className='column'>
          <p>Generale</p>
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            Acasă
          </Link>
          <HashLink to='/#despre-noi'>Despre Phomi</HashLink>
          <HashLink to='/#de-ce-noi'>De ce noi</HashLink>
          <HashLink to='/#calculator'>Calculator</HashLink>
        </div>
        <div className='column'>
          <p>Produse</p>
          <Link to='/produse'>Categorii</Link>
          <Link to='/produse'>Toate Produsele</Link>
        </div>
        <div className='column'>
          <p>Aplicații</p>
          <HashLink to='/#calculator'>Calculator Preț</HashLink>
          <HashLink to='/#contact'>Cerere Online</HashLink>
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            Chat Bot
          </Link>
        </div>
      </div>
      <div className='bottom'>2024 Phomi Md - All Rights Reserved</div>
    </S.Footer>
  );
};

export default Footer;
