import * as S from './styles';

const Steps = () => {
  return (
    <S.Steps>
      <div className='container'>
        <div className='row row-obtii'>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>SELECTEAZĂ</span>
              <br />
              modelul de ceramică flexibilă
              <br />
              pentru proiectul tău
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>SPECIFICĂ</span>
              <br />
              detaliile și preferințele tale
              <br />
              în formularul de comandă
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links'>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>CONFIRMĂ</span>
              <br />
              acordul pentru design și
              <br /> oferta personalizată
            </div>
          </div>
          <div className='col-md-3 col-6'>
            <div className='links' style={{ width: 2000 }}>
              <div className='obtii' />
            </div>
            <div className='text'>
              <span>PRIMEȘTE</span>
              <br />
              produsele tale Phomi, <br />
              pregătite pentru instalare
            </div>
          </div>
        </div>
      </div>
    </S.Steps>
  );
};

export default Steps;
