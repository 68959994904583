import { TypeAnimation } from 'react-type-animation';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { products } from '../../products';
import Product from '../ProduseHero/product';
import OutsideClickHandler from 'react-outside-click-handler';

const ProductModal = ({ onClose, onClick }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [showCategory, setShowCategory] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <S.ProductModal>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div>
          <svg
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='close'
            width={35}
            height={35}
            onClick={onClose}>
            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
            <g
              id='SVGRepo_tracerCarrier'
              stroke-linecap='round'
              stroke-linejoin='round'></g>
            <g id='SVGRepo_iconCarrier'>
              {' '}
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                fill='#212121'></path>{' '}
            </g>
          </svg>
          <div className='content'>
            <h1>
              <TypeAnimation
                sequence={[
                  'Produsele Phomi', // Types 'One'
                  0,
                ]}
                wrapper='span'
                cursor={true}
                repeat={Infinity}
                style={{
                  fontSize: '48px',
                  display: 'inline-block',
                  lineHeight: '50px',
                  fontWeight: '700',
                  minHeight: '32px',
                }}
              />
            </h1>
            <p className='subcontent'>
              Alege din peste 300 de variații disponibile atât pentru interior
              cât și pentru exterior.
            </p>
            <div className='products_wrapper'>
              <div className='sidebar'>
                <h3
                  id='produse_phomi'
                  onClick={() => {
                    windowWidth <= 768 && setShowCategory(!showCategory);
                    windowWidth <= 768 &&
                      document
                        .getElementById('produse_phomi')
                        .scrollIntoView({ behavior: 'smooth' });
                  }}>
                  Categorii{' '}
                  <svg
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={showCategory ? 'active' : ''}
                    width={30}
                    height={30}>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M12 6V18M12 18L7 13M12 18L17 13'
                        stroke='#1bb9a3'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </h3>
                {(showCategory || windowWidth >= 768) && (
                  <div className='category'>
                    {products
                      .sort((a, b) => b.variety.length - a.variety.length)
                      .map((elem, i) => (
                        <div
                          onClick={() => {
                            setSelectedCategory(elem?.category_name);
                            windowWidth <= 768 && setShowCategory(false);
                            document
                              .getElementById('produse_phomi')
                              .scrollIntoView({ behavior: 'smooth' });
                          }}
                          className={
                            selectedCategory === elem?.category_name
                              ? 'active'
                              : ''
                          }>
                          <p key={i}>{elem?.category_name}</p>
                          <span>{elem?.variety.length}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {windowWidth <= 768 && selectedCategory && (
                <p className='selected_categ'>
                  {selectedCategory}{' '}
                  <svg
                    viewBox='-0.5 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    stroke='#000000'
                    onClick={() => setSelectedCategory()}
                    width={15}
                    height={15}>
                    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M3 21.32L21 3.32001'
                        stroke='#fff'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                      <path
                        d='M3 3.32001L21 21.32'
                        stroke='#fff'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'></path>{' '}
                    </g>
                  </svg>
                </p>
              )}
              <div className='products'>
                {!selectedCategory ? (
                  <>
                    {products.map((product) => {
                      // Take only the first six items from the variety array
                      const firstSixVarieties = product.variety.slice(0, 6);

                      // Map over these six items and render them
                      return firstSixVarieties.map((variety, i) => (
                        <Product
                          data={variety}
                          categoryData={product}
                          onClick={() => {
                            onClick({ variety: variety, product: product });
                            onClose();
                          }}
                        />
                      ));
                    })}
                  </>
                ) : (
                  <>
                    {products
                      .filter(
                        (elem) => elem?.category_name === selectedCategory
                      )
                      .map((product) => {
                        return product.variety.map((variety, i) => (
                          <Product
                            data={variety}
                            categoryData={product}
                            onClick={() => {
                              onClick({ variety: variety, product: product });
                              onClose();
                            }}
                          />
                        ));
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </S.ProductModal>
  );
};

export default ProductModal;
