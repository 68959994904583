import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import { Background, Parallax } from 'react-parallax';

const About = () => {
  return (
    <>
      <S.About id='despre-noi'>
        <div>
          <div>
            <div>
              <h2>
                Despre <span>Phomi</span>
              </h2>
              <p>
                Phomi este liderul inovației în domeniul materialelor de
                construcție, revoluționând industria cu ceramica sa flexibilă.
                Creată din argilă naturală prin tehnologii avansate, ceramica
                Phomi oferă o soluție durabilă și estetică pentru orice proiect
                de design interior sau exterior.
              </p>
              <p>
                Produsele Phomi sunt ecologice, reflectând angajamentul
                companiei pentru sustenabilitate și inovație responsabilă.
                Ceramica flexibilă este ușor de instalat, necesitând un timp
                redus de montaj și minimizând impactul asupra mediului.
              </p>
              <p>
                Flexibilitatea unică a materialului permite aplicări diverse, de
                la fațade de clădiri până la finisaje interioare sofisticate.
                Variația largă de texturi, culori și modele deschide noi
                posibilități creative, transformând spațiile obișnuite în opere
                de artă moderne.
              </p>
              <div className='blocks'>
                <div>
                  <h3>Universalitate</h3>
                  <p>
                    Se potrivește deopotrivă bine atât pentru placarea
                    interioară, cât și exterioară, fiind folosit și ca material
                    pentru pardoseli.
                  </p>
                </div>
                <div>
                  <h3>Ușoara și Subțire</h3>
                  <p>
                    Materialele MSM sunt mai rezistente decât placările ceramice
                    sau din cărămidă, combinând flexibilitatea și ușurința.
                  </p>
                </div>
                <div>
                  <h3>Colecții Vagi</h3>
                  <p>
                    Peste 400 de opțiuni de texturi, dimensiuni și culori pentru
                    plăci, adecvate pentru cele mai îndrăznețe idei.
                  </p>
                </div>
                <div>
                  <h3>Rezistent la Foc și Apă</h3>
                  <p>
                    Poate fi folosit ca placare pe suprafețele exterioare ale
                    șemineului și în jurul piscinei.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <img src='/img2.jpeg' alt='Phomi MD' />
              <img src='/img2.webp' alt='Phomi MD' />
            </div>
          </div>
        </div>
      </S.About>
    </>
  );
};

export default About;
