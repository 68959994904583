import { useEffect, useState } from 'react';
import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

const DecePhomi = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNav, setOpenNav] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <S.Whyus id='de-ce-noi'>
      <div>
        <h2>De Ce Clienții Aleg Phomi</h2>
        <div>
          <p>
            Clienții aleg Phomi pentru calitatea excepțională și inovația
            produselor noastre, care combină estetica superioară cu
            durabilitatea, oferind soluții sustenabile și versatile pentru orice
            spațiu, transformând fiecare proiect într-o expresie unică a
            eleganței și funcționalității.
          </p>
          <a href={`/Catalog Phomi.pdf`} download='Catalog Phomi.pdf'>
            Descarcați Catalogul
          </a>
          {windowWidth > 768 ? (
            <div className='blocks'>
              <div>
                <h4>Universalitate</h4>
                <p>
                  Se potrivește perfect atât pentru finisajele interioare, cât
                  și exterioare, fiind de asemenea ideal ca material pentru
                  pardoseli.
                </p>
              </div>
              <div>
                <h4>Ușurință și Subțire</h4>
                <p>
                  Materialele MCM sunt mai rezistente decât finisajele din
                  ceramică sau cărămidă, oferind în același timp flexibilitate
                  și ușurință.
                </p>
              </div>
              <div>
                <h4>Variație Vastă de Colecții</h4>
                <p>
                  Oferim peste 400 de opțiuni de texturi, dimensiuni și culori
                  pentru cele mai îndrăznețe idei.
                </p>
              </div>
              <div>
                <h4>Rezistent la Foc și Apă</h4>
                <p>
                  Potrivit pentru utilizare ca placări exterioare pe suprafețele
                  șemineelor și în jurul piscinelor.
                </p>
              </div>
              <div>
                <h4>Ecologic Pur</h4>
                <p>
                  Fără chimicale. 100% argilă modificată. Certificat ecologic în
                  peste 15 țări, inclusiv Europa, SUA și China.
                </p>
              </div>
              <div>
                <h4>Montaj Simplu</h4>
                <p>
                  Montarea nu necesită fixări suplimentare, sisteme de profiluri
                  sau pregătire complexă.
                </p>
              </div>
              <div>
                <h4>Adaptabilitate Climatică</h4>
                <p>
                  Materialele noastre rezistă la variații mari de temperatură,
                  fiind ideale pentru orice condiții climatice.
                </p>
              </div>
              <div>
                <h4>Durabilitate Extinsă</h4>
                <p>
                  Produsele Phomi mențin calitatea și aspectul pe termen lung,
                  oferind durabilitate fără compromisuri.
                </p>
              </div>
            </div>
          ) : (
            <Swiper
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              className='mySwiper'
              spaceBetween={12}
              slidesPerView={4}
              autoplay={{
                delay: 2000,
              }}
              breakpoints={{
                346: {
                  // width: 576,
                  slidesPerView: 1,
                },
                500: {
                  // width: 576,
                  slidesPerView: 2,
                },
                768: {
                  // width: 768,
                  slidesPerView: 4,
                },
              }}>
              <SwiperSlide>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
                <div>
                  <h4>Lorem, ipsum dolor.</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          )}
        </div>
        <div className='images'>
          <div>
            <img src='/why1.jpeg' alt='why1' />
          </div>
          <div>
            <img src='/why2.jpeg' alt='why1' />
          </div>
          <div>
            <img src='/why3.webp' alt='why1' />
          </div>
          <div>
            <img src='/why4.jpeg' alt='why1' />
          </div>
        </div>
      </div>
    </S.Whyus>
  );
};

export default DecePhomi;
