import styled, { css } from 'styled-components';

const helperSlidesInAnimation = css`
  @keyframes helper-slides-in {
    0% {
      top: -100px;
    }

    25% {
      top: 40px;
    }

    100% {
      top: 40px;
    }
  }

  -webkit-animation: helper-slides-in 4s 1; /* Safari 4+ */
  -moz-animation: helper-slides-in 4s 1; /* Fx 5+ */
  -o-animation: helper-slides-in 4s 1; /* Opera 12+ */
  animation: helper-slides-in 4s 1; /* IE 10+, Fx 29+ */
`;
export const Success = styled.p`
  ${helperSlidesInAnimation}
  padding: 12px 16px;
  border-radius: 8px;
  background: #1bb9a4;
  color: #fff;
  font-size: 14px;
  padding-right: 60px;
  position: relative;
  margin-left: auto;
  margin-top: -20px;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: top 0.2s, opacity 0.1s;
  z-index: 999999999900000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  br {
    display: none;
  }
  @media (max-width: 768px) {
    max-width: 360px;
    width: 100%;
    font-size: 13px;
    br {
      display: block;
    }
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    max-width: 25px;
  }
`;

export const ProduseHero = styled.div`
  min-height: 70vh;
  margin-top: 123px;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
  .banner {
    width: 100%;

    .swiper-container-free-mode > .swiper-wrapper {
      transition-timing-function: linear;
    }
    .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      -o-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }
    .swiper-slide {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        width: 100%;
        z-index: 10;
        @media (max-width: 768px) {
          max-width: 100%;
          height: 200px;
        }
      }
    }
    position: relative;
    & > img {
      width: 100%;
      z-index: 0;
      opacity: 0.1;
    }
  }
  .content {
    max-width: 1170px;
    margin: 32px auto;
    @media (max-width: 1220px) {
      padding: 0 15px;
    }
    h1 {
      position: relative;
      &::after {
        position: absolute;
        width: 100px;
        height: 5px;
        content: '';
        bottom: 5px;
        background: linear-gradient(to right, #212121, #1bb9a4);
        left: 0;
      }
      span {
        font-size: 32px;
        color: blue;
        /* Create a linear gradient from black to blue */
        background: linear-gradient(to right, #212121, #1bb9a4);
        /* Clip the background so it is only behind the text */
        -webkit-background-clip: text;
        background-clip: text;
        /* Make the text color transparent, so the gradient is visible */
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        font-size: 46px;
        margin-bottom: 18px;
        position: relative;
        width: fit-content;
        @media (max-width: 768px) {
          font-size: 38px !important;
          margin-bottom: 8px;
        }
        @media (max-width: 390px) {
          font-size: 35px !important;
        }
      }
    }
    .subcontent {
      font-size: 18px;
      margin-top: 6px;
      font-weight: 300;
    }
    .products_wrapper {
      display: flex;
      align-items: flex-start;
      gap: 48px;
      margin-top: 64px;
      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 32px;
        gap: 12px;
      }
      .selected_categ {
        padding: 4px 12px;
        background: #1bb9a3;
        color: #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        svg {
          max-width: 20px;
          width: 15px;
          height: 15px;
          margin-left: 12px;
        }
      }
      .sidebar {
        max-width: 30%;
        width: 100%;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        h3 {
          font-weight: 300;
          margin-bottom: 32px;
          font-size: 28px;
          scroll-margin-top: 160px;
          @media (max-width: 768px) {
            scroll-margin-top: 80px;
          }
          @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 12px;
            font-size: 18px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 4px;
            margin-bottom: 7px;
          }
          svg {
            display: none;
            @media (max-width: 768px) {
              display: flex;
              max-width: 30px;
              &.active {
                transform: rotate(180deg);
              }
            }
          }
        }
        .category {
          div {
            display: flex;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 4px 8px;
            transition: 0.3s ease-in-out;
            border-radius: 6px;
            cursor: pointer;
            border: 1px solid transparent;
            &:hover {
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border: 1px solid #1bb9a3;
              p {
                color: #1bb9a3;
              }
            }
            &.active {
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border: 1px solid #1bb9a3;
              p {
                color: #1bb9a3;
              }
            }
            p {
              font-size: 18px;
              color: #3e3935;
              cursor: pointer;
              transition: 0.3s ease-in-out;
            }
            span {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #d8f1ed;
              color: #3e3935;
              border-radius: 50%;
              font-size: 14px;
            }
          }
        }
      }
      .products {
        max-width: 70%;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 8px;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        & > div {
          max-width: calc(33% - 6px);
          @media (max-width: 1000px) {
            max-width: calc(50% - 6px);
            width: 100%;
          }
        }
      }
    }
  }
`;

export const Product = styled.div`
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
  svg {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    transition: 0.3s ease-in-out;
    opacity: 1;
    z-index: 9;
    /* ${({ isFavorite }) => isFavorite && `stroke: blue; fill: blue;`} */
    &:hover {
      opacity: 0.7;
    }
  }
  &:hover {
    transform: scale(1.03);
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 115px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
  p {
    font-size: 14px;
    color: rgb(87, 87, 87);
    span {
      font-size: 20px;
      font-weight: 500;
      color: rgb(62, 57, 53);
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  .options {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 32px;
    p {
      &:first-child {
        background: #1bb9a3;
        color: #fff;
        padding: 4px 8px;
        border-radius: 8px;
        @media (max-width: 422px) {
          font-size: 12px;
        }
        @media (max-width: 379px) {
          padding: 4px 6px;
          font-size: 11px;
        }
      }
      &:last-child {
        background: #cf5b4c;
        color: #3e3935;
        padding: 4px 8px;
        border-radius: 8px;
        color: #fff;
        @media (max-width: 422px) {
          font-size: 12px;
        }
        @media (max-width: 379px) {
          padding: 4px 6px;
          font-size: 11px;
        }
      }
    }
  }
  h3 {
    font-size: 18px;
    margin-top: 4px;
    font-weight: 700;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    width: fit-content;
    font-family: Poppins, sans-serif, sans-serif;
    display: flex;
    font-family: 'Inter', sans-serif;
    span {
      font-size: 12px;
      margin-bottom: 1px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 1px;
    }
  }
`;
