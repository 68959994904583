import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Home from './Home';
import ProdusePage from './Produse';
import FavoritePage from './favorite';

const Routes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={(props) => {
          return <Home {...props} />;
        }}
      />
      <Route
        path='/produse'
        exact
        render={(props) => {
          return <ProdusePage {...props} />;
        }}
      />
      <Route
        path='/favorite'
        exact
        render={(props) => {
          return <FavoritePage {...props} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
