import Header from './components/Header';
import Footer from './components/Footer';
import Favorites from './components/Favorites';

const FavoritePage = () => {
  return (
    <>
      <Header />
      <Favorites />
      <Footer />
    </>
  );
};

export default FavoritePage;
