import { useLocation } from 'react-router-dom';
import About from './components/About';
import ReactAnimation from './components/BgAnimated';
import BgAnimated from './components/BgAnimated';
import Calculator from './components/Calculator';
import Contact from './components/Contact';
import DecePhomi from './components/DecePhomi';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import InfoSection from './components/InfoSection';
import Produse from './components/Produse';
import { useEffect } from 'react';
import FaqComponent from './components/Faq';
import Caracteristici from './components/Caracteristici';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.scroll && location?.state?.scroll !== '') {
      const toLocation = document.getElementById(location?.state.scroll);
      if (toLocation) {
        toLocation.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Produse />
      <InfoSection />
      <Calculator />
      <DecePhomi />
      <Caracteristici />
      <FaqComponent />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
