import styled from 'styled-components';

export const Favorites = styled.div`
  margin-top: 150px;
  max-width: 1170px;
  margin: 150px auto 0;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    margin-top: 80px;
  }
  & > p {
    color: #3e3935;
    font-size: 18px;
  }
  h1 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 16px;
    font-weight: 700;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    width: fit-content;
    &::after {
      position: absolute;
      width: 100px;
      height: 3px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  .no_products {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-size: 32px;
      color: #3e3935;
      font-weight: 500;
    }
    a {
      font-size: 16px;
      border-radius: 8px;
      border: none;
      padding: 8px 16px;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transition: 0.3s ease-in-out;
      background: #1bb9a4;
      margin-top: 16px;
      &:hover {
        cursor: pointer;
        background: #169482;
      }
    }
  }
  .produse {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
    margin-top: 42px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      gap: 0 16px;
      margin-bottom: -18px;
    }
    & > div {
      max-width: calc(33% - 20px);
      width: 100%;
      @media (max-width: 768px) {
        max-width: calc(50% - 20px);
      }
      @media (max-width: 400px) {
        max-width: 100%;
      }
    }
  }
`;
