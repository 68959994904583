import { Link } from 'react-router-dom';
import * as S from './styles';
import ReactPlayer from 'react-player';

const InfoSection = () => {
  return (
    <S.InfoSection>
      <div>
        <div>
          <h2>
            Vă așteptăm în <br />
            Showroom-ul Nostru
          </h2>
          <p>
            Oferim peste 200 de variații de produse, disponibile pentru
            dumneavoastră în showroom-ul nostru.
          </p>
          <p>
            Aveți libertatea de a ne vizita direct pe strada Puskin 56, de a
            trimite o cerere de ofertă online, sau de a ne contacta telefonic la
            numărul 079 900 999 pentru orice detalii sau consultanță de care
            aveți nevoie.
          </p>
          <Link
            to='#'
            onClick={() => {
              document
                .getElementById('contact')
                .scrollIntoView({ behavior: 'smooth' });
            }}>
            Completați Cererea Online
          </Link>
        </div>
        <ReactPlayer url='/Fabiola.mp4' controls />
        {/* <video controls>
          <source src='/IMG_0307.MP4' type='video/mp4' />
          Your browser does not support the video tag.
        </video> */}
      </div>
    </S.InfoSection>
  );
};

export default InfoSection;
