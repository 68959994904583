import { useState } from 'react';
import * as S from './styles';
import Slider from '@mui/material/Slider';
import Steps from './Steps';
import ProductModal from '../ProductModal';
import { useEffect } from 'react';

const Calculator = () => {
  const [metri, setMetri] = useState(30);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedDimension, setSelectedDimension] = useState();
  const [metraj, setMetraj] = useState(20);
  const [price, setPrice] = useState(1000);

  useEffect(() => {
    if (selectedProduct) {
      setSelectedDimension(selectedProduct?.product?.options?.[0]);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedDimension) {
      if (selectedDimension === selectedProduct?.product?.options?.[0]) {
        setPrice(
          ((metraj * 1000) / parseInt(selectedDimension.split(' x ')[0])) *
            selectedProduct?.product?.price_small
        );
      } else if (selectedDimension === selectedProduct?.product?.options?.[1]) {
        setPrice(
          ((metraj * 1000) / parseInt(selectedDimension.split(' x ')[0])) *
            selectedProduct?.product?.price_big
        );
      }
    }
  }, [selectedProduct, metraj, selectedDimension]);

  return (
    <>
      {openModal && (
        <ProductModal
          onClose={() => setOpenModal(false)}
          onClick={setSelectedProduct}
        />
      )}
      <S.Calculator id='calculator'>
        <div>
          <h3>Calculator Online</h3>
          <div>
            <div>
              {selectedProduct ? (
                <div className='produsul'>
                  <h2>Produsul</h2>
                  <div className='produs'>
                    <svg
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className='close'
                      width={35}
                      height={35}
                      onClick={() => setSelectedProduct()}>
                      <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                      <g
                        id='SVGRepo_tracerCarrier'
                        stroke-linecap='round'
                        stroke-linejoin='round'></g>
                      <g id='SVGRepo_iconCarrier'>
                        {' '}
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                          fill='#212121'></path>{' '}
                      </g>
                    </svg>
                    <img
                      src={`${selectedProduct?.product?.path}/${selectedProduct?.variety?.img}`}
                      alt='Phomi'
                    />
                    <span>
                      {selectedProduct?.product?.category_name} -{' '}
                      {selectedProduct?.variety?.name}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='produsul' onClick={() => setOpenModal(true)}>
                  <h2>Produsul</h2>
                  <p>Alege Produsul</p>
                </div>
              )}
              <div className='dimensiune'>
                <h2>Dimensiunea</h2>
                <div>
                  {selectedProduct ? (
                    <>
                      <p
                        className={
                          selectedDimension ===
                          selectedProduct?.product?.options?.[0]
                            ? 'active'
                            : ''
                        }
                        onClick={() =>
                          setSelectedDimension(
                            selectedProduct?.product?.options?.[0]
                          )
                        }>
                        {selectedProduct?.product?.options?.[0]}
                      </p>
                      {selectedProduct?.product?.options?.[1] && (
                        <p
                          className={
                            selectedDimension ===
                            selectedProduct?.product?.options?.[1]
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            setSelectedDimension(
                              selectedProduct?.product?.options?.[1]
                            )
                          }>
                          {selectedProduct?.product?.options?.[1]}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <p className='active'>1200 x 600</p>
                      <p>2400 x 1200</p>
                    </>
                  )}
                </div>
              </div>
              <div className='metrajul'>
                <div className='title'>
                  <h2>Metrajul</h2>
                  <input
                    type='text'
                    maxLength={5}
                    value={metraj}
                    onChange={(e) =>
                      setMetraj(e.target.value.replace(/[^0-9]/g, ''))
                    }
                  />
                  <p>
                    m<span>2</span>
                  </p>
                </div>
                <div>
                  <Slider
                    aria-label='Temperature'
                    defaultValue={30}
                    valueLabelDisplay='auto'
                    value={metraj}
                    onChange={(e, value) => setMetraj(value)}
                    min={10}
                    max={500}
                    color='secondary'
                  />
                </div>
              </div>
            </div>
            <div className='content'>
              <h4>Prețul Final</h4>
              <span>{parseInt(price).toLocaleString()} MDL</span>
              <p>
                Calculul realizat prin intermediul calculatorului online este
                unul estimativ și nu reprezintă un angajament în vederea
                încheierii unei tranzacţii.
              </p>
              <p>
                Dacă ai nevoie de o consultare personalizată, contactează
                079900999.
              </p>
              <div>
                <a href='/Catalog Phomi.pdf' download='Catalog Phomi.pdf'>
                  Descarcă Catalogul
                </a>
                <button
                  onClick={() => {
                    document
                      .getElementById('contact')
                      .scrollIntoView({ behavior: 'smooth' });
                  }}>
                  Solicită Oferta
                </button>
              </div>
            </div>
          </div>
        </div>
      </S.Calculator>
      <Steps />
    </>
  );
};

export default Calculator;
