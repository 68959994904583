import { Link } from 'react-router-dom';
import ReactAnimation from '../BgAnimated';
import QuestionBlock from './QuestionBlock';
import * as S from './styles';
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {
  return (
    <S.Hero>
      {/* <video autoPlay muted loop className='background-video'>
        <source src='/video.mp4' type='video/mp4' />
        Your browser does not support HTML5 video.
      </video> */}
      <ReactAnimation />
      <div className='content'>
        <div>
          <h1
            style={{
              fontSize: '48px',
              display: 'inline-block',
              lineHeight: '50px',
              fontWeight: '700',
              minHeight: '100px',
            }}>
            Phomi - Inovația ce Definește Viitorul
          </h1>
          <p>
            Phomi redefinește estetica și funcționalitatea spațiilor prin
            ceramica sa inovatoare și flexibilă. Materialul revoluționar asigură
            durabilitate și adaptabilitate pentru orice proiect arhitectural.
            {/* <br />
            <br />
            Explorând limitele designului modern, Phomi stabilește noi standarde
            în construcții și finisaje interioare. */}
          </p>
          <div className='buttons'>
            <Link
              to='#'
              onClick={() => {
                document
                  .getElementById('galerie')
                  .scrollIntoView({ behavior: 'smooth' });
              }}>
              Vezi Produsele
            </Link>
            <Link
              to='#'
              onClick={() => {
                document
                  .getElementById('contact')
                  .scrollIntoView({ behavior: 'smooth' });
              }}>
              Contactează-ne
            </Link>
          </div>
        </div>
        <QuestionBlock />
      </div>
    </S.Hero>
  );
};

export default Hero;
