import { Link } from 'react-router-dom';
import * as S from './styles';
import Calculator from './svgs';
import Cerere from './svgs/cerere';
import Phone from './svgs/phone';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openNav, setOpenNav] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', function () {
      var navbar = document.getElementById('mobile-navbar');
      if (window.pageYOffset > 0 && navbar?.classList) {
        // Checks if page is scrolled more than 0 pixels
        navbar?.classList?.add('scrolled');
      } else {
        navbar?.classList?.remove('scrolled');
      }
    });
  }, []);

  return (
    <>
      {windowWidth > 768 ? (
        <S.Header>
          <div>
            <Link to='/' onClick={() => window.scrollTo(0, 0)}>
              <img src='/icon-white.png' alt='Phomi' />
            </Link>
            <div
              className='phone'
              onClick={() => (window.location.href = 'tel:079900999')}>
              <Phone />
              <p>079 900 999</p>
            </div>
            <div className='blocks'>
              <HashLink to='/#calculator'>
                <Calculator />
                <p>Calculator Metraj</p>
              </HashLink>
              <HashLink to='/#contact'>
                <Cerere />
                <p>Cerere Online</p>
              </HashLink>
            </div>
          </div>
          <div className='bottom_links'>
            <Link
              to='/'
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}>
              Acasă
            </Link>
            <HashLink to='/#despre-noi'>Despre Phomi</HashLink>
            <Link to='/produse'>Produse</Link>
            <HashLink to='/#galerie'>Galerie</HashLink>
            <HashLink to='/#contact'>Contactează-ne</HashLink>
            <Link to='/favorite' className='favorite'>
              <svg
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                  id='SVGRepo_tracerCarrier'
                  stroke-linecap='round'
                  stroke-linejoin='round'></g>
                <g id='SVGRepo_iconCarrier'>
                  {' '}
                  <path
                    d='M6.03954 7.77203C3.57986 8.32856 2.35002 8.60682 2.05742 9.54773C1.76482 10.4886 2.60325 11.4691 4.2801 13.4299L4.71392 13.9372C5.19043 14.4944 5.42868 14.773 5.53586 15.1177C5.64305 15.4624 5.60703 15.8341 5.53498 16.5776L5.4694 17.2544C5.21588 19.8706 5.08912 21.1787 5.85515 21.7602C6.62118 22.3417 7.77268 21.8115 10.0757 20.7512L10.6715 20.4768C11.3259 20.1755 11.6531 20.0248 12 20.0248C12.3469 20.0248 12.6741 20.1755 13.3285 20.4768L13.9243 20.7512C16.2273 21.8115 17.3788 22.3417 18.1449 21.7602C18.9109 21.1787 18.7841 19.8706 18.5306 17.2544M19.7199 13.4299C21.3968 11.4691 22.2352 10.4886 21.9426 9.54773C21.65 8.60682 20.4201 8.32856 17.9605 7.77203L17.3241 7.62805C16.6251 7.4699 16.2757 7.39083 15.9951 7.17781C15.7144 6.96479 15.5345 6.64193 15.1745 5.99623L14.8468 5.40837C13.5802 3.13612 12.9469 2 12 2C11.0531 2 10.4198 3.13613 9.15316 5.40838'
                    stroke='#1BB9A4'
                    stroke-width='1.5'
                    stroke-linecap='round'></path>{' '}
                </g>
              </svg>{' '}
              Favorite
            </Link>
          </div>
        </S.Header>
      ) : (
        <S.MobileHeader id='mobile-navbar'>
          <div>
            <Link
              className='logo'
              to='/'
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}>
              <img src='/icon-white.png' alt='Phomi' />
            </Link>
            <div
              className='phone'
              onClick={() => (window.location.href = 'tel:079900999')}>
              <Phone />
              <p>079 900 999</p>
            </div>
            <button
              type='button'
              className='chakra-button css-1tei9w9'
              aria-label='Open menu'
              onClick={() => setOpenNav(!openNav)}
              style={{ marginTop: '-7px' }}>
              <span className='chakra-button__icon css-1wh2kri'>
                <span className={openNav ? 'css-1pvzqor' : 'css-za1uc2'} />
              </span>
            </button>
          </div>
          {openNav && (
            <div className='menu_mobile'>
              <div>
                <Link
                  to='/'
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setOpenNav(false);
                  }}>
                  Acasă
                </Link>
                <HashLink
                  to='/#despre-noi'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  Despre Phomi
                </HashLink>
                <Link
                  to='/produse'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  Produse
                </Link>
                <HashLink
                  to='/#galerie'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  Galerie
                </HashLink>
                <HashLink
                  to='/#contact'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  Contactează-ne
                </HashLink>
                <Link
                  to='/favorite'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  Favorite
                </Link>
              </div>
              <div className='blocks'>
                <HashLink
                  to='/#calculator'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  <Calculator width={40} height={40} />
                  <p>Calculator Metraj</p>
                </HashLink>
                <HashLink
                  to='/#contact'
                  onClick={() => {
                    setOpenNav(false);
                  }}>
                  <Cerere width={40} height={40} />
                  <p>Cerere Online</p>
                </HashLink>
              </div>
              <div
                className='block_individual'
                onClick={() => (window.location.href = 'tel:079900999')}>
                <img src='/assets/bxs_phone-call.svg' alt='Numar de telefon' />
                <p>+373 799 00 999</p>
              </div>
              <div
                className='block_individual'
                onClick={() =>
                  (window.location.href = 'mailto:phomi.md@gmail.com')
                }>
                <img src='/assets/ic_sharp-email.svg' alt='Numar de telefon' />
                <p>phomi.md@gmail.com</p>
              </div>
              <div
                className='block_individual'
                onClick={() =>
                  window.open(
                    'https://maps.app.goo.gl/vSY3wPcN6pJGd3Bc7',
                    '_blank'
                  )
                }>
                <img
                  src='/assets/carbon_location-filled.svg'
                  alt='Numar de telefon'
                />
                <p>str. Puskin 56, Chisinau</p>
              </div>
            </div>
          )}
        </S.MobileHeader>
      )}
    </>
  );
};

export default Header;
