import Header from './components/Header';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import ScrollToTop from './TopScroll';
import { Widget } from '@uploadcare/react-widget';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes />
    </Router>
  );
}

export default App;
