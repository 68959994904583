import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import * as S from './styles';
import Box from './Box';

const FaqComponent = () => {
  const faq = [
    {
      question: 'Ce este ceramica flexibilă Phomi?',
      answ: 'Ceramica flexibilă Phomi este un material inovator realizat din argilă modificată, oferind flexibilitate, durabilitate și o estetică naturală pentru diverse aplicații în construcții și design interior.',
    },
    {
      question: 'Cum se instalează produsele Phomi?',
      answ: 'Produsele Phomi se instalează rapid și ușor, fără necesitatea de fixări suplimentare sau de pregătire complexă a suprafeței, ceea ce face ca procesul de montaj să fie eficient și accesibil.',
    },
    {
      question: 'Pot folosi ceramica Phomi pentru exterior?',
      answ: 'Da, ceramica flexibilă Phomi este ideală atât pentru placări interioare, cât și exterioare, fiind rezistentă la variațiile de temperatură și condițiile meteorologice.',
    },
    {
      question: 'Este ceramica Phomi ecologică?',
      answ: 'Absolut. Phomi se angajează în practici ecologice, folosind argilă 100% modificată, fără aditivi chimici, ceea ce face ca produsele să fie ecologice și sigure pentru mediu și utilizatori.',
    },
    {
      question: 'Câte varietăți de produse Phomi există?',
      answ: 'Phomi oferă o gamă largă de produse, cu peste 400 de varietăți de texturi, culori și dimensiuni, pentru a se potrivi oricărui proiect de design și arhitectură.',
    },
    {
      question: 'Produsele Phomi sunt rezistente la foc și apă?',
      answ: 'Da, produsele Phomi sunt concepute pentru a fi rezistente atât la foc cât și la apă, făcându-le soluția ideală pentru utilizare într-o varietate de medii, inclusiv în jurul șemineelor sau bazinelor.',
    },
  ];
  return (
    <S.FaqComponent>
      <h2>Întrebari Frecvente</h2>
      <p>
        Explorați răspunsurile la unele dintre cele mai comune întrebări despre
        Phomi și produsele noastre revoluționare de ceramica flexibilă. Aflați
        mai multe despre beneficiile și utilizările unice ale produselor.
      </p>
      <div className='column'>
        <div>
          {faq?.slice(0, 3).map((e, i) => (
            <>
              <Box question={e?.question} answer={e?.answ} />
            </>
          ))}
        </div>
        <div>
          {faq?.slice(3, 10).map((e, i) => (
            <>
              <Box question={e?.question} answer={e?.answ} />
            </>
          ))}
        </div>
      </div>
    </S.FaqComponent>
  );
};

export default FaqComponent;
