import { useEffect, useState } from 'react';
import * as S from './styles';
import { Link } from 'react-router-dom';
import Contact from '../Contact';
import Product from '../ProduseHero/product';

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [storageChange, setStorageChange] = useState(0); // New state to trigger re-render

  useEffect(() => {
    const handleFavoritesUpdate = () => {
      // Retrieve the favorites from sessionStorage
      const storedFavorites =
        JSON.parse(sessionStorage.getItem('favorites')) || [];
      // Set the retrieved favorites to the state
      setFavorites(storedFavorites);
    };

    // Set up event listener for custom event
    window.addEventListener('favoritesUpdated', handleFavoritesUpdate);

    // Perform initial load of favorites
    handleFavoritesUpdate();

    // Clean up event listener
    return () => {
      window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
    };
  }, []);
  return (
    <>
      <S.Favorites>
        <h1>Produsele Favorite</h1>
        <p>Aici puteți găsi lista produselor marcate ca Favorite.</p>
        <div>
          {favorites?.length >= 1 ? (
            <div className='produse'>
              {favorites?.map((elem) => (
                <Product data={elem.data} categoryData={elem.categoryData} />
              ))}
            </div>
          ) : (
            <div className='no_products'>
              <h2>Nu aveți Produse Favorite</h2>
              <Link to='/produse'>Explorați Produsele Phomi</Link>
            </div>
          )}
        </div>
      </S.Favorites>
      <Contact fromFavorites />
    </>
  );
};

export default Favorites;
