const Star = ({ ...props }) => {
  return (
    <svg
      {...props}
      height='30px'
      width='200px'
      version='1.1'
      id='_x32_'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      fill='#fff'
      stroke='#fff'>
      <g id='SVGRepo_bgCarrier' strokeWidth={0} />
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <style
          type='text/css'
          dangerouslySetInnerHTML={{ __html: ' .st0{fill:' }}
        />
      </g>
      #fff;{'}'}{' '}
      <g>
        {' '}
        <path
          className='st0'
          d='M437.021,74.978C390.764,28.684,326.624-0.014,256,0C185.375-0.014,121.235,28.684,74.978,74.978 C28.691,121.236-0.015,185.375,0,256c-0.015,70.625,28.691,134.764,74.978,181.022C121.235,483.309,185.375,512.008,256,512 c70.624,0.008,134.764-28.691,181.021-74.978C483.308,390.764,512.007,326.625,512,256 C512.007,185.375,483.308,121.236,437.021,74.978z M405.073,405.073c-38.221,38.184-90.786,61.736-149.073,61.75 c-58.288-0.014-110.853-23.566-149.074-61.75C68.735,366.853,45.191,314.287,45.176,256c0.014-58.287,23.559-110.853,61.75-149.081 C145.147,68.735,197.712,45.184,256,45.176c58.287,0.008,110.852,23.559,149.073,61.743c38.191,38.228,61.735,90.794,61.75,149.081 C466.808,314.287,443.264,366.853,405.073,405.073z'
        />{' '}
        <path
          className='st0'
          d='M341.353,225.86l-55.449-2.507l-19.529-51.971c-1.625-4.324-5.758-7.192-10.39-7.192 c-4.61,0-8.75,2.868-10.382,7.192l-19.522,51.971l-55.463,2.507c-4.61,0.206-8.617,3.258-10.044,7.654 c-1.434,4.397,0.023,9.221,3.626,12.103l43.396,34.625l-14.757,53.522c-1.22,4.456,0.434,9.206,4.177,11.927 c3.75,2.714,8.779,2.824,12.64,0.279l46.331-30.573l46.346,30.573c3.86,2.544,8.889,2.434,12.632-0.279 c3.743-2.72,5.397-7.471,4.176-11.927l-14.75-53.522l43.39-34.625c3.618-2.882,5.067-7.706,3.632-12.103 C349.985,229.118,345.97,226.066,341.353,225.86z'
        />{' '}
      </g>{' '}
    </svg>
  );
};

export default Star;
