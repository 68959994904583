import OutsideClickHandler from 'react-outside-click-handler';
import * as S from './styles';
import { useState } from 'react';
import axios from 'axios';

const CerereProdus = ({ onClose, selectedProduct, onSuccess }) => {
  const [data, setData] = useState({});
  const [isRedBorder, setIsRedBorder] = useState(false);

  const submit = () => {
    if (
      !data ||
      !data?.nume ||
      !data?.prenume ||
      !data?.telefon ||
      data?.telefon?.length < 6
    ) {
      setIsRedBorder(true);
      return;
    } else {
      setIsRedBorder(false);
      axios
        .post('https://submit-form.com/HpDoeQwXk', {
          Nume: data?.nume,
          Prenume: data?.prenume,
          Telefon: data?.telefon,
          'Produs de Referinta': selectedProduct
            ? `${selectedProduct?.product?.category_name} - ${selectedProduct?.variety?.name}`
            : '',
        })
        .then((res) => {
          setData({ nume: '', prenume: '', telefon: '', email: '', mesaj: '' });
          onClose();
          onSuccess();
        })
        .catch((err) => console.log(err));
      return;
    }
  };

  return (
    <S.CerereProdus>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div>
          <div className='header'>
            <h2>Trimite Cererea Online</h2>
            <svg
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='close'
              width={35}
              height={35}
              onClick={onClose}>
              <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
              <g
                id='SVGRepo_tracerCarrier'
                stroke-linecap='round'
                stroke-linejoin='round'></g>
              <g id='SVGRepo_iconCarrier'>
                {' '}
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z'
                  fill='#212121'></path>{' '}
              </g>
            </svg>
          </div>
          <div className='contents'>
            <p className='subcontent'>
              Completati cererea de mai jos cu datele dumnevoastra de contact
              iar echipa noastra va reveni cu un sunet cat mai curând.{' '}
              <span>
                Intre timp va incurajam sa explorati gama noastra de produse,
                pentru a avea o imagine mai clara asupra preferintelor
                dumneavoastra.
              </span>
            </p>
            <div className='first_row'>
              <div>
                <img
                  src={`${selectedProduct?.product?.path}/${selectedProduct?.variety?.img}`}
                  alt='Phomi'
                />
                <h3>
                  {selectedProduct?.product?.category_name} -{' '}
                  {selectedProduct?.variety?.name}
                </h3>
                <p className='pret'>
                  De la{' '}
                  <strong>
                    {selectedProduct?.product?.price_small} MDL/m
                    <sup>2</sup>
                  </strong>
                </p>
                <h4>Dimensiuni disponibile:</h4>
                <div className='options'>
                  {' '}
                  <p>{selectedProduct?.product?.options[0]}</p>
                  {selectedProduct?.product?.options[1] && (
                    <p>{selectedProduct?.product?.options[1]}</p>
                  )}
                </div>
              </div>
              <div className='form'>
                <div
                  className={`input ${
                    isRedBorder && !data?.nume ? 'red_border' : ''
                  }`}>
                  <p>Nume</p>
                  <input
                    value={data?.nume}
                    placeholder='Nume'
                    onChange={(e) =>
                      setData({ ...data, nume: e?.target?.value })
                    }
                  />
                </div>
                <div
                  className={`input ${
                    isRedBorder && !data?.prenume ? 'red_border' : ''
                  }`}>
                  <p>Prenume</p>
                  <input
                    value={data?.prenume}
                    placeholder='Prenume'
                    onChange={(e) =>
                      setData({ ...data, prenume: e?.target?.value })
                    }
                  />
                </div>
                <div
                  className={`input ${
                    (isRedBorder && !data?.telefon) ||
                    (data?.telefon?.length < 6 && isRedBorder)
                      ? 'red_border'
                      : ''
                  }`}>
                  <p>Telefon</p>
                  <input
                    value={data?.telefon}
                    placeholder='+373'
                    onChange={(e) =>
                      setData({
                        ...data,
                        telefon: e?.target?.value.replace(/[^0-9]/g, ''),
                      })
                    }
                  />
                </div>
                <button onClick={submit}>Trimite Cererea</button>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </S.CerereProdus>
  );
};

export default CerereProdus;
