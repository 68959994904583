import styled from 'styled-components';

export const Caracteristici = styled.div`
  max-width: 1170px;
  margin: 64px auto;
  @media (max-width: 1220px) {
    padding: 0 15px;
  }
  h2 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 16px;
    font-weight: 700;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    width: fit-content;
    margin-bottom: 32px;
    &::after {
      position: absolute;
      width: 100px;
      height: 3px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  & > h3 {
    text-align: center;
    margin-top: 64px;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 16px;
    font-weight: 700;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    width: fit-content;
    margin: 64px auto;
    @media (max-width: 768px) {
      font-size: 24px;
    }
    &::after {
      position: absolute;
      width: 100px;
      height: 3px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 50%;
      transform: translate(-50%, 0);
    }
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & > div:not(.certificate) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .table {
      max-width: 65%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 24px;
      }
      h3 {
        font-size: 24px;
        margin-bottom: 12px;
        margin-top: 24px;
        font-weight: 500;
        color: #3e3935;
      }
      & > div {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 8px;
        & > div {
          display: flex;
          justify-content: space-between;
          padding: 8px 16px;
          border: 1px solid rgb(227, 229, 234);
          @media (max-width: 768px) {
            flex-wrap: wrap;
            p {
              width: 100%;
              &:first-child {
                font-weight: 600;
              }
              &:last-child {
                font-weight: 300;
              }
            }
          }
          &:not(:first-child) {
            border-top: none;
          }
          &:first-child {
            border-radius: 8px 8px 0 0;
          }
          &:last-child {
            border-radius: 0 0 8px 8px;
          }
          p {
            font-size: 18px;
            color: #3e3935;
            @media (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .video {
      max-width: 30%;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 300px;
      }
      & > div {
        width: 100% !important;
        height: auto !important;
        video {
          border-radius: 8px;
          overflow: hidden;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
      }
    }
  }
  .certificate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      max-width: 250px;
      @media (max-width: 768px) {
        margin-bottom: 32px;
      }
    }
    & > div {
      &:last-child {
        max-width: 70%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px 32px;
        @media (max-width: 768px) {
          max-width: 100%;
        }
        & > div {
          display: flex;
          flex-direction: column;
          gap: 16px;
          max-width: 49%;
          @media (max-width: 768px) {
            max-width: 100%;
            padding-left: 25px;
          }
          & > p {
            max-width: 100%;
            width: 100%;
            color: #3e3935;
            position: relative;
            font-weight: 500;
            &:before {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #1bb9a4;
              bottom: 0;
              left: -16px;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }
      }
    }
  }
`;
