import styled from 'styled-components';

export const FaqComponent = styled.div`
  margin-top: 40px;
  max-width: 1170px;
  margin: 40px auto 160px;
  padding-top: 32px;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 32px 15px;
  }
  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 8px;
    font-size: 32px;
    color: blue;
    /* Create a linear gradient from black to blue */
    background: linear-gradient(to right, #212121, #1bb9a4);
    /* Clip the background so it is only behind the text */
    -webkit-background-clip: text;
    background-clip: text;
    /* Make the text color transparent, so the gradient is visible */
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    font-size: 46px;
    margin-bottom: 18px;
    position: relative;
    width: fit-content;
    &::after {
      position: absolute;
      width: 100px;
      height: 5px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to right, #212121, #1bb9a4);
      left: 0;
    }
    @media (max-width: 768px) {
      font-size: 36px !important;
      margin-bottom: 30px;
    }
    @media (max-width: 390px) {
      font-size: 32px !important;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 36px;
    }
    @media (max-width: 490px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media (max-width: 420px) {
      font-size: 27px;
      line-height: 36px;
    }
  }
  & > p {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    a {
      color: #065fd4;
    }
  }
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 96px;
    @media (max-width: 768px) {
      margin-top: 32px;
      gap: 12px;
    }
    & > div {
      max-width: calc(50% - 8px);
      width: 100%;
      gap: 12px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`;
export const Box = styled.div`
  background: #1bb9a3;
  padding: 18px 24px 10px;
  border-radius: 7px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media (max-width: 768px) {
    padding: 12px 34px 10px 18px;
  }
  height: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  svg {
    max-width: 25px;
    position: absolute;
    top: -1px;
    right: 14px;
    @media (max-width: 768px) {
      top: -9px;
    }
    transition: 0.3s ease-in-out;
    &.expanded {
      transform: rotate(180deg);
    }
  }
  h3 {
    color: #fff;
    user-select: none;
    @media (max-width: 768px) {
      font-size: 16px;
      font-weight: 500;
    }
  }
  p {
    font-size: 14px;
    color: #fff;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  }
  .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    font-size: 14px;
    color: #fff;
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 4px;
      font-size: 12px;
    }
  }
  .answer.expanded {
    max-height: 200px; // Adjust based on the content size
  }
`;
